import React from "react"
import Client from "../../../../components/general/Client"
import { ContainerElement } from "./styled"

export const Brink: React.FC = () => {
  return (
    <ContainerElement>
      <Client
        title={"Brink"}
        description={
          "Brink Climate Systems streeft ernaar om comfort en gezondheid in woningen, kantoren of bedrijfspanden te creëren. Met onze oplossingen op het gebied van ventilatie, verwarming, koeling en warmwaterbereiding bereiken we dit."
        }
      />
    </ContainerElement>
  )
}
