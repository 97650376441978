import React from "react"
import { Brink as BrinkComponent } from "../components/pages/clients/Brink/Brink"
import Layout from "../components/layout"

const Brink = () => (
  <Layout>
    <BrinkComponent />
  </Layout>
)

export default Brink
